<template>
  <section v-if="portfolioCollection && portfolioCollection.isAllowed" id="portfolio">
    <PageTitle :title="portfolioCollection.title" icon="mdi-laptop"/>

    <v-container fluid>
      <!-- project card -->
      <v-list>
        <v-list-item class="d-flex flex-wrap justify-center">
          <div v-for="(project, index) in getProjectsByFilter" :key="index">
            <template v-if="!project.softDeleted">
              <v-card
                width="400px"
                height="530px"
                class="ma-5 grey darken-4 white--text"
              >
                <v-card-title class="d-block" style="height: 6rem;">
                  <div class="text-center mt-n2">{{project.title}}</div>
                  <div v-if="typeof project.endDate == 'string'" class="text-center grey--text caption">
                    {{formatDate(project.startDate) + ' - ' + project.endDate}}
                  </div>
                  <div v-else class="text-center grey--text caption">
                    {{formatDate(project.startDate) + ' - ' + formatDate(project.endDate)}}
                  </div>
                  <div class="d-flex justify-center mt-2">
                    <template v-if="activeFilterName === 'all'">
                      <v-chip
                        v-for="(item, idx) in project.languages"
                        :key="item.title+idx"
                        small
                        class="mx-1 text-uppercase card-chip caption"
                      >{{item.title}}</v-chip>
                      <v-chip
                        v-for="(item, idx) in project.frameworks"
                        :key="item.title+idx"
                        small
                        class="mx-1 text-uppercase card-chip"
                      >{{item.title}}</v-chip>
                    </template>
                    <template v-else>
                      <v-chip
                        v-for="(item, idx) in project[activeFilterName]"
                        :key="idx"
                        small
                        class="mx-1 text-uppercase card-chip"
                      >{{item.title}}</v-chip>
                    </template>
                  </div>
                </v-card-title>

                <!-- Project image + overlay-->
                <div
                  class="d-flex justify-center pa-4"
                  style="position: relative; height: calc(100% - 6rem);"
                >
                  <!-- Project image -->
                  <v-img
                    contain
                    @mouseover="hoverProject(project.title + index, 'visible')"
                    :src="project.images[0].url"
                  />
                  <!-- Overlay -->
                  <div
                    :ref="project.title + index"
                    @mouseleave="hoverProject(project.title + index, 'hidden')"
                    class="pa-4"
                    style="position: absolute; top: 0; left: 0; height: 100%; width: 100%; visibility: hidden;"
                  >
                    <div
                      class="d-flex justify-center align-center"
                      style="height: 100%; background: rgba(0,0,0, 0.8);"
                    >
                      <div
                        style="
                          position: relative;
                          height: 160px;
                          width: 160px;
                          border-radius: 50%;
                          background: rgba(30,30,30, 1);
                        "
                      >
                        <!-- Info -->
                        <v-btn
                          @click="openDialog('information', project)"
                          fab small dark
                          style="height: 40px; width: 40px; position: absolute; top: 8px; left: 50%; transform: translate(-50%, 0);"
                        >
                          <v-tooltip bottom color="primary">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on" color="white">mdi-information-outline</v-icon>
                            </template>
                            <span>View Information</span>
                          </v-tooltip>
                        </v-btn>

                        <!-- Github -->
                        <v-btn
                          @click="openDialog('github', project)"
                          :disabled="!project.githublink"
                          fab small dark
                          style="height: 40px; width: 40px; position: absolute; top: calc(50% - 20px); right: 8px; translate(0, -50%)"
                        >
                          <v-tooltip bottom color="primary">
                            <template v-slot:activator="{ on, attrs }">
                              <a v-bind="attrs" v-on="on" target="_blank" :href="project.githublink"><v-icon color="white">mdi-github</v-icon></a>
                            </template>
                            <span>View GitHub Repo</span>
                          </v-tooltip>
                        </v-btn>

                        <!-- Carousel -->
                        <v-btn
                          @click="openDialog('gallery', project)"
                          fab small dark
                          style="height: 40px; width: 40px; position: absolute; bottom: 8px; left: 50%; transform: translate(-50%, 0);"
                        >
                          <v-tooltip bottom color="primary">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on" color="white">mdi-camera</v-icon>
                            </template>
                            <span>View Gallery</span>
                          </v-tooltip>
                        </v-btn>

                        <!-- Host -->
                        <v-btn
                          @click="openDialog('host', project)"
                          :disabled="!project.hostlink"
                          fab small dark
                          style="height: 40px; width: 40px; position: absolute; top: calc(50% - 20px); left: 8px; translate(0, -50%)"
                        >
                          <v-tooltip bottom color="primary">
                            <template v-slot:activator="{ on, attrs }">
                              <a v-bind="attrs" v-on="on" target="_blank" :href="project.hostlink"><v-icon color="white">mdi-spider-web</v-icon></a>
                            </template>
                            <span>View Host Domain</span>
                          </v-tooltip>
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </div>
              </v-card>
            </template>
          </div>
        </v-list-item>
      </v-list>

      <!-- Project Dialog -->
      <v-dialog
        v-model="modal.show"
        dark
        width="600"
      >
        <v-card dark>
          <v-toolbar color="primary" height="80">
            <div>
              <h3>{{modal.project.title}}</h3>
              <h5 v-if="typeof modal.project.endDate == 'string'" style="font-size: 13px">
                {{formatDate(modal.project.startDate) + ' - ' + modal.project.endDate}}
              </h5>
              <h5 v-else style="font-size: 13px">{{formatDate(modal.project.startDate) + ' - ' + formatDate(modal.project.endDate)}}</h5>
            </div>
            <v-spacer></v-spacer>
            <div>
              <!-- Info -->
              <v-btn
                @click="openDialog('information', modal.project)"
                fab small dark
                class="mr-2"
                style="height: 40px; width: 40px;"
              >
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      :color="(modal.entry === 'information') ? 'primary' : 'white'"
                    >mdi-information-outline</v-icon>
                  </template>
                  <span>View Information</span>
                </v-tooltip>
              </v-btn>

              <!-- Carousel -->
              <v-btn
                @click="openDialog('gallery', modal.project)"
                fab small dark
                class="mr-2"
                style="height: 40px; width: 40px;"
              >
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      :color="(modal.entry === 'gallery') ? 'primary' : 'white'"
                    >mdi-camera</v-icon>
                  </template>
                  <span>View Gallery</span>
                </v-tooltip>
              </v-btn>

              <!-- Github Link -->
              <v-btn
                v-if="modal.project.githublink"
                fab small dark
                class="mr-2"

                style="height: 40px; width: 40px;"
              >
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <a v-bind="attrs" v-on="on" target="_blank" :href="modal.project.githublink">
                      <v-icon color="white">mdi-github</v-icon>
                    </a>
                  </template>
                  <span>View GitHub Repo</span>
                </v-tooltip>
              </v-btn>

              <!-- Host -->
              <v-btn
                v-if="modal.project.hostlink"
                fab small dark
                style="height: 40px; width: 40px;"
              >
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <a v-bind="attrs" v-on="on" target="_blank" :href="modal.project.hostlink">
                      <v-icon color="white">mdi-spider-web</v-icon>
                    </a>
                  </template>
                  <span>View Host Domain</span>
                </v-tooltip>
              </v-btn>
            </div>
          </v-toolbar>
          <v-card-text>
            <!-- Information -->
            <template v-if="modal.entry === 'information'">
              <!-- Languages -->
              <h3 class="mt-3">Languages</h3>
              <div class="d-flex mt-2 mx-1">
                <template v-for="(language, i) in modal.project.languages">
                  <div :key="i" class="text-center mr-5">
                    <v-img width="46" class="mx-auto" contain :src="language.url"/>
                    <span class="text-uppercase">{{language.title}}</span>
                  </div>
                </template>
              </div>

              <!-- Frameworks -->
              <template v-if="modal.project.frameworks && modal.project.frameworks.length > 0">
                <h3 class="mt-5">Frameworks</h3>
                <div class="d-flex mt-2 mx-1">
                  <template v-for="(framework, i) in modal.project.frameworks">
                    <div :key="i" class="text-center">
                      <v-img width="46"  class="mx-auto" contain :src="framework.url"/>
                      <span class="text-uppercase">{{framework.title}}</span>
                    </div>
                  </template>
                </div>
              </template>

              <!-- Description -->
              <h3 class="mt-5">Description</h3>
              <p class="mt-2 mb-4" v-html="modal.project.description"></p>
            </template>

            <!-- Gallery -->
            <template v-if="modal.entry === 'gallery'">
              <v-carousel
                hide-delimiter-background
                show-arrows-on-hover
                class="portfolio-gallery d-flex justify-center mt-4 mx-auto"
                style="width: 450px; height: 550px;"
                contain
              >
                <v-carousel-item
                  v-for="(image,i) in modal.project.images"
                  :key="i"
                  :src="image.url"
                  reverse-transition="fade-transition"
                  transition="fade-transition"
                  :style="($vuetify.breakpoint.xs) ? { border: '3px solid purple' } : ''"
                  contain
                ></v-carousel-item>
                <template v-slot:prev="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    small
                    fab
                    color="primary"
                  ><v-icon>mdi-arrow-left</v-icon></v-btn>
                </template>
                <template v-slot:next="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    small
                    fab
                    color="primary"
                  ><v-icon>mdi-arrow-right</v-icon></v-btn>
                </template>
              </v-carousel>
            </template>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </section>
</template>

<script>
import firebaseGetCollection from '@/mixins/firebase8/collection/get';
import PageTitle from '@/components/shared/PageTitle.vue';
import { format } from 'date-fns';
import _ from 'lodash';

export default {
  name: 'Portfolio',

  components: {
    PageTitle,
  },

  data() {
    return {
      portfolioCollectionRaw: null,
      activeFilterName: 'languages',
      activeCategoryNames: ['all'],

      modal: {
        show: false,
        project: {},
      },
    };
  },

  computed: {
    portfolioCollection() {
      return (this.portfolioCollectionRaw && this.portfolioCollectionRaw.results)
        ? this.portfolioCollectionRaw.results[0]
        : null;
    },

    getProjectsByFilter() {
      let projects = [..._.orderBy(this.portfolioCollection.projects, ['startDate'], ['desc'])];

      if (this.activeFilterName === 'frameworks') {
        projects = projects.filter((element) => {
          if (element.frameworks && element.frameworks.length > 0) {
            return 1;
          }
          return 0;
        });
      }

      if (this.activeCategoryNames[0] !== 'all') {
        const results = [];

        projects.forEach((project) => {
          if (project[this.activeFilterName]) {
            let success = true;

            this.activeCategoryNames.forEach((category) => {
              const items = project[this.activeFilterName];

              if (!items.map((item) => item.title).join(' ').includes(category)) {
                success = false;
              }
            });

            if (success) { results.push(project); }
          }
        });

        return results;
      }

      return projects;
    },
  },

  async created() {
    await this.subToCollection();
  },

  destroyed() {
    this.unSubToCollection();
  },

  methods: {
    async subToCollection() {
      this.portfolioCollectionRaw = await firebaseGetCollection('portfolio');
    },

    unSubToCollection() {
      this.portfolioCollectionRaw = null;
    },

    hoverProject(key, value) {
      this.$refs[key][0].style.visibility = value;
    },

    setModalProject(project) {
      this.modal.show = true;
      this.modal.project = project;
      this.modal.project.path = project.title.replace(/\s/g, '');
    },

    formatDate(date) {
      if (date) return format(date.toDate(), 'dd/MM/yyyy');
      return 'current';
    },

    openDialog(entry, project) {
      this.modal.entry = entry;

      this.modal.project = project;

      this.modal.show = false;
      this.modal.show = true;
    },
  },
};
</script>

<style lang="scss" scoped>
  .v-list {
    background: #2C2D2F !important;
  }

  .filter {
    border: 1px solid #fff !important;
    border-radius: 5px !important;
    &:hover {
      border: 1px solid #5078FF !important;
    }
  }

  .active-filter {
    background: #5078FF !important;
    border: 1px solid #5078FF !important;
    &:hover {
      background: #4065dd !important;
    }
  }

  .category-chip {
    display: flex;
    justify-content: center;
    min-width: 80px !important;
    color: #fff !important;
    background: none !important;
    border: 1px solid #fff !important;
    border-radius: 10px !important;
    &:hover {
      border: 1px solid #5078FF !important;
    }
  }

  .category-chip.active-category {
    background: #5078FF !important;
    border: 1px solid #5078FF !important;
    color: #fff !important;

    &:hover {
      background: #4065dd !important;
    }
  }

  .card-chip {
    color: #fff !important;
    font-size: 10px !important;
    background: none !important;
    border: 1px solid #5078FF !important;
  }
</style>
